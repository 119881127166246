import {createStore} from 'vuex'

export default createStore({
    state: {
        mainSwipe: 0,
        phone: '+7 (499) 226-15-52',
        reservePhone: '+7 (915) 241-16-27',
        email: 'contact@edutb.ru',
        currentProgram: 0,
        programs: [{
            name: 'Охрана труда',
            description: 'Работодателям и персоналу, руководящим работникам и рядовым сотрудникам в организациях и учреждениях любой формы и сферы деятельности необходимо обладать знаниями по охране труда. В рамках определенных должностных полномочий  необходимо также специальное обучение по охране труда.\n' +
                'Наша компания предлагает качественное обучение по программам повышения квалификации, профессиональной переподготовки в области охраны труда и смежных направлений. Мы также проводим инструктажи и семинары. ',
            directions: [
                {
                    name: 'Охрана труда для руководителей и специалистов',
                    price: '3700',
                    time: '40 ч.',
                    img: '1.webp'
                },
                {
                    name: 'Для членов комиссий по проверке знаний требований охраны труда',
                    price: '3700',
                    time: '40 ч.',
                    img: '2.webp'
                },
                // {
                //     name: 'Профессиональная переподготовка (специалист по охране труда)',
                //     price: 'Цена договорная',
                //     time: '256 ч.',
                //     img: '3.jpeg'
                // },
                {
                    name: 'Внеочередная проверка знаний требований охраны труда',
                    price: '1600',
                    time: '256 ч.',
                    img: '3.jpeg'
                },
                {
                    name: 'Разработка и функционирование системы управления охраной труда',
                    price: '5000',
                    time: '40 ч.',
                    img: '3.jpeg'
                },
                {
                    name: 'Охрана труда для руководителей и специалистов медицинских организация',
                    price: '3700',
                    time: '40 ч.',
                    img: '3.jpeg'
                },
            ]
        }, {
            name: 'Пожарная безопасность',
            description: 'Обеспечение качественной работы в сфере пожарной безопасности – это залог отсутствия рисков для предприятия и жизни людей.\n' +
                'Наша компания осуществляет профессиональную подготовку в области пожарной безопасности. Слушатели смогут пройти курс «Пожарно-технический минимум» и противопожарный инструктаж. Мы также проводим подготовку пожарных и руководителей добровольных пожарных дружин.',
            directions: [
                {
                    name: 'Пожарная безопасность',
                    price: '3100',
                    time: '16 ч.',
                    img: '4.webp'
                },
                {
                    name: 'Проф. подготовка пожарных добровольных пожарных дружин',
                    price: '3800',
                    time: '16 ч.',
                    img: '5.webp'
                },
                {
                    name: 'Противопожарный инструктаж',
                    price: '29000',
                    time: '16 ч.',
                    img: '6.webp'
                },
                {
                    name: 'Проф. подготовка руководителей добровольных пожарных дружин',
                    price: '3800',
                    time: '16 ч.',
                    img: '7.webp'
                },
                {
                    name: 'Подготовка руководителей добровольных пожарных команд',
                    price: '5500',
                    time: '16 ч.',
                    img: '7.webp'
                },
                {
                    name: 'Внеочередная проверка знаний требований пожарной безопасности',
                    price: '1800',
                    time: '16 ч.',
                    img: '7.webp'
                },
            ]
        }, {
            name: 'Оказание первой помощи',
            description: 'Навыки по оказанию первой помощи – крайне важные умения в системе охраны труда на предприятиях.\n' +
                'У нас - лучшие курсы скорой помощи в Москве! Уникальная собственная программа подготовки.',
            directions: [
                {
                    name: 'Оказание первой помощи при несчастном случае на пр-ве',
                    price: '2300',
                    time: '16 ч.',
                    img: '8.webp'
                },
                {
                    name: 'Оказание первой помощи в неотложных ситуациях для работников соц. защиты',
                    price: '1900',
                    time: '16 ч.',
                    img: '9.webp'
                },
                {
                    name: 'Оказание первой помощи в образовательных организациях',
                    price: '2300',
                    time: '16 ч.',
                    img: '10.webp'
                },
                {
                    name: 'Инструктор массового обучения навыкам оказания первой помощи после несчастного случая или терр. акта',
                    price: '10500',
                    time: '40 ч.',
                    img: '10.webp'
                },
            ]
        }, {
            name: 'Электробезопасность',
            description: 'Знания по электробезопасности – важная составляющая охраны труда. Обучение в этом направлении проходят все сотрудники компаний, чья трудовая деятельность связана с электрооборудованием. Группа допуска присваивается в зваисимости от должностных обязанностей.  Работники с основным общим или средним образованием должны пройти первичное обучение не менее 72 часов.',
            directions: [
                {
                    name: 'Электробезопасность 1 группа (инструктаж)',
                    price: '29000',
                    time: '4 ч.',
                    img: '11.webp'
                },
                {
                    name: 'Электробезопасность 2 группа до 1000 В',
                    price: '4600',
                    time: '36 ч.',
                    img: '12.webp'
                },
                {
                    name: 'Электробезопасность 3 группа до 1000 В',
                    price: '4600',
                    time: '36 ч.',
                    img: '13.webp'
                },
                {
                    name: 'Электробезопасность 4 группа до и выше 1000 В',
                    price: '4600',
                    time: '36 ч.',
                    img: '14.webp'
                },
                {
                    name: 'Электробезопасность 4 группа до 1000 В с правом инспектирования',
                    price: '5500',
                    time: '36 ч.',
                    img: '15.webp'
                },
                {
                    name: 'Электробезопасность 3 группа до и выше 1000 В',
                    price: '8600',
                    time: '36 ч.',
                    img: '16.webp'
                },
                {
                    name: 'Электробезопасность 4 группа допуска до и выше 1000 В',
                    price: '8600',
                    time: '36 ч.',
                    img: '17.webp'
                },
                {
                    name: 'Электробезопасность 4 группа допуска с правом испытаний до и выше 1000 В',
                    price: '10600',
                    time: '36 ч.',
                    img: '18.webp'
                },
                {
                    name: 'Электробезопасность 5 группа допуска до и выше 1000 В',
                    price: '8600',
                    time: '36 ч.',
                    img: '19.webp'
                },
                {
                    name: 'Электробезопасность 5 группа допуска с правом испытаний до и выше 1000 В',
                    price: '10600',
                    time: '36 ч.',
                    img: '20.webp'
                },
            ]
        }, {
            name: 'Тепловые энергоустановки',
            description: 'Чтобы в ходе эксплуатации энергоустановок не нарушался технологический процесс, не происходило чрезвычайных ситуаций, повреждения техники и приборов, а также травм, необходимо иметь надежные знания о работе с подобным оборудованием. В случае, если в должностные обязанности специалистов входит организация безопасной работы с энергоустановками, необходимо пройти квалифицированное обучение. Тем сотрудникам, кто обслуживает энергоустановки, аттестацию нужно проходить каждый год.\n' +
                'В рамках нашего обучения специалисты получат знания об актуальных правилах технической эксплуатации и обслуживания тепловых энергоустановок, а также законодательных нормативах эксплуатации данного оборудования.',
            directions: [
                {
                    name: 'Эксплуатация тепловых энергоустановок',
                    price: '5500',
                    time: '72 ч.',
                    img: '21.webp'
                },
            ]
        }, {
            name: 'Работы на высоте',
            description: 'Высотные работы связаны с большим риском, поэтому сотрудники предприятий, чья деятельность связана на объектах на высоте более пяти метров, должны проходить специальное обучение.  Сотрудники, работающие на высоте до пяти метров, также проходят обучение, без присвоения группы допуска. Работа на высоте делится на различные группы по безопасности.\n' +
                '\n' +
                'Мы предлагаем профессиональное обучение по квалификации работ на высоте – начиная с базовых основ и безопасности работ до самых сложных групп допуска.',
            directions: [
                {
                    name: 'Безопасные методы и приемы выполнения работ на высоте',
                    price: '3500',
                    time: '24 ч.',
                    img: '22.webp'
                },
                {
                    name: 'Безопасные методы и приемы выполнения работ на высоте для работников 1 группы',
                    price: '3500',
                    time: '40 ч.',
                    img: '23.webp'
                },
                {
                    name: 'Безопасные методы и приемы выполнения работ на высоте для работников 2 группы',
                    price: '3500',
                    time: '40 ч.',
                    img: '24.webp'
                },
                {
                    name: 'Безопасные методы и приемы выполнения работ на высоте для работников 3 группы',
                    price: '3500',
                    time: '40 ч.',
                    img: '25.webp'
                },
            ]
        }, {
            name: 'Гражданская оборона',
            description: 'Любое предприятие обязано быть готовым предпринять меры в случае чрезвычайной ситуации. Для этого руководители и сотрудники должны обладать компетенциями в обеспечении функционирования системы ГО и ЧС. Все сотрудники обязаны проходить инструктаж, а те специалисты, в чьи обязанности входят задачи ГО, - проходить углубленное обучение.\n' +
                'Наша учебная программа позволяет получить все необходимые теоретические знания и практические навыки в сфере гражданской обороны.',
            directions: [
                {
                    name: 'Курсовое обучение по гражданской обороне (ответственные лица)',
                    price: '3300',
                    time: '40 ч.',
                    img: '26.webp'
                },
                {
                    name: 'Гражданская оборона и защита населения от ЧС природного и техногенного характера для уполномоченных по решению задач в области ГО И ЧС',
                    price: '3300',
                    time: '40 ч.',
                    img: '26.webp'
                },
                {
                    name: 'Организация работы комиссии по предупреждению и ликвидации ЧС и обеспечению пожарной безопасности для организаций, не отнесенных к категории по ГО',
                    price: '3300',
                    time: '40 ч.',
                    img: '26.webp'
                },
                {
                    name: 'Вводный инструктаж по гражданской обороне',
                    price: '29000',
                    time: '40 ч.',
                    img: '26.webp'
                },
                {
                    name: 'Курсовое обучение по гражданской обороне (работающее население)',
                    price: '3300',
                    time: '40 ч.',
                    img: '26.webp'
                },
                {
                    name: 'Гражданская оборона и защита населения от ЧС природного и техногенного характера для руководителей организаций, не отнесенных к категории по ГО',
                    price: '3300',
                    time: '40 ч.',
                    img: '26.webp'
                },
                {
                    name: 'Курсовое обучение личного состава нештатных формирований по обеспечению выполнения мероприятий по гражданской обороне',
                    price: '3300',
                    time: '40 ч.',
                    img: '26.webp'
                },
                {
                    name: 'Подготовка руководителей групп занятий по гражданской обороне и защите от чрезвычайных ситуаций организаций, учреждений, предприятий',
                    price: '3300',
                    time: '40 ч.',
                    img: '26.webp'
                },
            ]
        }, {
            name: 'Лифты и эскалаторы',
            description: 'Лифты и эскалаторы – сложная техника, которая постоянно обслуживает нас в бизнес-центрах, общественных местах, многоквартирных домах. Чтобы такое оборудование работало бесперебойно и было безопасным, персонал, обслуживающий эти инженерные системы, должен быть тщательно обучен и иметь профессиональные знания.\n' +
                'Наши опытные преподаватели обучат сотрудников, которые работают с подобными механизмами. На наших курсах вы получите необходимые знания и квалификацию, с учетом действующих профстандартов и законодательства.',
            directions: [
                {
                    name: 'Лифтер',
                    price: '4000',
                    time: '40 ч.',
                    img: '27.webp'
                },
                {
                    name: 'Специалист по организации эксплуатации лифтов',
                    price: '7500',
                    time: '72 ч.',
                    img: '28.webp'
                },
                {
                    name: 'Специалист по организации ТО и ремонта лифтов',
                    price: '7500',
                    time: '72 ч.',
                    img: '29.webp'
                },
                {
                    name: 'Специалист, ответственный за организацию эксплуатации платформ подъемных для инвалидов',
                    price: '7500',
                    time: '40 ч.',
                    img: '30.webp'
                },
                {
                    name: 'Специалист, ответственный за организацию ТО и ремонта платформ подъемных для инвалидов',
                    price: '7500',
                    time: '40 ч.',
                    img: '31.webp'
                },
                {
                    name: 'Специалист, ответственный за организацию эксплуатации эскалаторов и пассажирских конвейеров',
                    price: '7500',
                    time: '40 ч.',
                    img: '32.webp'
                },
                {
                    name: 'Специалист, ответственный за организацию ТО и ремонта эскалаторов и пассажирских конвейеров',
                    price: '7500',
                    time: '40 ч.',
                    img: '32.webp'
                },
                {
                    name: 'Оператор платформ подъемных для инвалидов',
                    price: '3500',
                    time: '40 ч.',
                    img: '32.webp'
                },
                {
                    name: 'Дежурный (оператор) у эскалатора',
                    price: '3500',
                    time: '40 ч.',
                    img: '32.webp'
                },
                {
                    name: 'Оператор поэтажного эскалатора (пассажирского конвейера)',
                    price: '3500',
                    time: '40 ч.',
                    img: '32.webp'
                },
                {
                    name: 'Оператор (диспетчер) диспетчерской службы по контролю работы лифтов и инженерного оборудования зданий и сооружений',
                    price: '4000',
                    time: '40 ч.',
                    img: '32.webp'
                },
                {
                    name: 'Диспетчер объединенной диспетчерской службы',
                    price: '4000',
                    time: '40 ч.',
                    img: '32.webp'
                },
            ]
        }, {
            name: 'Экологическая безопасность',
            description: 'Сегодня внимание к экологической безопасности производства особенно остро. Любому предприятию необходимо соблюдать требования экологической безопасности, рационально управлять своей природоохранной деятельностью, а также обучать персонал, ответственный за охрану окружающей среды.\n' +
                'Наше обучение дает все важнейшие профессиональные навыки по практической деятельности в связи с экологической безопасностью работы предприятия, по организации различных работ, знания по нормативным показателям состояния окружающей среды и экологической безопасности, законодательству в области охраны окружающей среды и т.д.',
            directions: [
                {
                    name: 'Экологическая безопасность',
                    price: '11400',
                    time: '112 ч.',
                    img: '33.webp'
                },
                {
                    name: 'Деятельность по обращению с отходами в  медицинских организациях',
                    price: '8500',
                    time: '72 ч.',
                    img: '33.webp'
                },
                {
                    name: 'Профессиональная подготовка лиц на право работы с отходами I-IV класса опасности',
                    price: '7100',
                    time: '112 ч.',
                    img: '33.webp'
                },
                {
                    name: 'Охрана окружающей среды и обеспечение экологической безопасности руководителями, специалистами общехозяйственных систем управления',
                    price: '11400',
                    time: '112 ч.',
                    img: '33.webp'
                },
                {
                    name: 'Обеспечение экологической безопасности при работах в области обращения с отходами I-IV классов опасности',
                    price: '10000',
                    time: '112 ч.',
                    img: '34.webp'
                },
            ]
        }, {
            name: 'Биологическая безопасность',
            description: 'Микроорганизмы III-IV групп патогенности представляют наибольшую опасность для человека. Несоблюдение правил безопасности при работе с ними может привести к заражению и создать угрозу для жизни и здоровья человека. Поэтому сотрудникам лабораторий, контактирующим с такими микроорганизмами, важно проходить соответствующую подготовку. Обучение безопасной работе с патогенами является одним из требований для получения лицензии на деятельность в области использования возбудителей инфекционных заболеваний человека и животных, согласно Постановлению Правительства РФ от 16 апреля 2012 г. № 317.\n' +
                'У нас Вы повысите квалификацию в данной области. Обучение направлено на расширение специальных профессиональных знаний и получение новых компетенций в области биологической безопасности.',
            directions: [
                {
                    name: 'Безопасность работы с микроорганизмами III-IV групп патогенности и возбудителями паразитарных заболеваний',
                    price: '9200',
                    time: '72 ч.',
                    img: '35.jpg'
                },
                {
                    name: 'Безопасность работы с микроорганизмами I-II групп патогенности',
                    price: '11000',
                    time: '72 ч.',
                    img: '35.jpg'
                },
            ]
        }, {
            name: 'Дезинфекция и стерилизация',
            description: 'Проведение дезинфекционных мероприятий в организациях является неотъемлемой частью обеспечения санитарно-эпидемиологического благополучия населения, пренебрежение которым может поставить под угрозу здоровье и жизнь людей. Согласно, требованиям СанПиН, такие работы может выполнять только специально обученный персонал.\n' +
                'У нас Вы можно пройти необходимые программы повышения квалификации в области санитарно-эпидемиологической безопасности для специалистов разных профилей. Программы разработаны с учетом законодательства в этой сфере, а также особенностей профессиональной деятельности работников.',
            directions: [
                {
                    name: 'Обеспечение дезинфекции и стерилизации руководителями, специалистами в медицинских организациях',
                    price: '5000',
                    time: '72 ч.',
                    img: '36.jpg'
                },
                {
                    name: 'Проведение дезинфекционных, дезинсекционных и дератизационных работ в организациях различного профиля',
                    price: '5000',
                    time: '72 ч.',
                    img: '36.jpg'
                },
                {
                    name: 'Дезинфекция вентиляционных систем',
                    price: '5000',
                    time: '72 ч.',
                    img: '36.jpg'
                },
                {
                    name: 'Обеспечение эпидемиологической безопасности эндоскопических вмешательств',
                    price: '8500',
                    time: '72 ч.',
                    img: '36.jpg'
                },
            ]
        }, {
            name: 'Коронавирусная инфекция COVID-19',
            description: 'Вы можете пройти повышение квалификации в области профилактики, диагностики и лечения коронавирусной инфекции COVID-19.\n' +
                'Обучение имеет целью формирование и совершенствование способности и готовности специалистов здравоохранения к профилактике, ранней диагностике, дифференциальной диагностике, а также лечению новой коронавирусной инфекции COVID-19, моделированию социальных, экономических, эпидемиологических и других условий оказания медицинской помощи, оказывающих влияние на здоровье и качество жизни населения.',
            directions: [
                {
                    name: 'Актуальные вопросы профилактики, диагностики и лечения коронавирусной инфекции COVID-19',
                    price: '4900',
                    time: '36 ч.',
                    img: '37.jpg'
                },
                {
                    name: 'Короновирусная инфекция COVID-19: особенности работы младшего медицинского персонала',
                    price: '1000',
                    time: '36 ч.',
                    img: '37.jpg'
                },
            ]
        }, {
            name: 'Сосуды, работающие под давлением',
            description: 'Сосуды, работающие под давлением, - это герметически закрытые емкости для химических и тепловых процессов, а также для хранения и перевозки сжатых, сжиженных и растворенных газов и жидкостей под давлением. Работа с подобными сосудами связана с повышенным риском и поэтому обязывает иметь профессиональные знания.\n' +
                'Персонал, обслуживающий сосуды, работающие под давлением, обязан  1 раз в год проходить профессиональное обучение, а затем 1 раз в пять лет - курсы повышения квалификации.\n' +
                'Наша программа обучения обеспечивает овладение всеми необходимыми профессиональными знаниями и навыками в этой сфере деятельности.',
            directions: [
                {
                    name: 'Безопасная эксплуатация сосудов, работающих под давлением',
                    price: '3300',
                    time: '24 ч.',
                    img: '38.webp'
                },
                {
                    name: 'Слесарь по эксплуатации ремонту газового оборудования',
                    price: '4000',
                    time: '72 ч.',
                    img: '39.webp'
                },
                {
                    name: 'Оператор котельной',
                    price: '4000',
                    time: '72 ч.',
                    img: '39.webp'
                },
                {
                    name: 'Ответственный за исправное состояние и безопасную эксплуатацию сосудов, работающих под давлением (не регистрируемых в органах Ростехнадзора)',
                    price: '5000',
                    time: '72 ч.',
                    img: '39.webp'
                },
            ]
        }]
    },
    getters: {},
    mutations: {
        currentProgram(state, currentProgram) {
            state.currentProgram = currentProgram
        },
        mainSwipe(state, swipe) {
            state.mainSwipe = swipe
        },
    },
    actions: {},
    modules: {}
})
