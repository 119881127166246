<template>
  <div id="home" class="home flex justify-center flex-col w-11/12 lg:w-7/12 mx-auto">
    <div class="flex flex-col lg:flex-row items-center justify-around">
      <img src="/images/logo-white.png" class="pointer-events-none hidden lg:block" style="max-height: 300px;">
  <div class="title flex justify-center gap-3 flex-col pt-6 lg:pt-0">
    <h1 class='text-3xl lg:text-4xl lg:text-left lg:ml-12 text-left uppercase font-bold'>{{ msg }}</h1>
    <div class="flex flex-col lg:flex-row lg:ml-12 justify-start lg:justify-start lg:gap-6">
      <div class="contact text-2xl justify-start lg:justify-center font-bold flex flex-col">
        <a class="text-left" href="tel:+74992261552">+7 (499) 226-15-52</a>
        <a class="text-left" href="tel:+79152411627">+7 (915) 241-16-27</a>
      </div>
      <div class="contact text-2xl font-bold flex flex-col">
        <a class="text-left" :href="'mailto:' + getEmail">{{ getEmail }}</a>
      </div>
    </div>
  </div>
    </div>
    <!--    <p>{{ tweened.toFixed(0) }}</p>-->
  </div>
</template>

<script>
export default {
  name: 'TheHeader',
  props: {
    msg: String
  },
  computed: {
    getPhone() {
      console.log(this.$store.state.phone);
      return this.$store.state.phone
    },
    getReservePhone() {
      return this.$store.state.reservePhone
    },
    getEmail() {
      return this.$store.state.email
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
  transition: 0.1s;
}
a:hover {
  color: #31b92c !important;
}
</style>
