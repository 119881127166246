<template>
  <div id="nav" class="w-full bg-blue-500 text-white flex flex-col gap-5 lg:flex-row justify-around shadow-md">
    <router-link class="" to="/"><div class="inline-block px-2 text-2xl pointer-events-none cursor-pointer font-bold">📚 ОБРАЗОВАНИЕ</div></router-link>
    <ul class="flex gap-6 text-xl font-semibold items-center">
      <li><router-link class="" to="/">Главная</router-link></li> |
      <li><router-link class="" to="/about">О нас</router-link></li> |
      <li><router-link class="" to="/program/all">Услуги</router-link></li><!-- |-->
<!--    <router-link class="" to="/about">Аутсорсинг и аудит</router-link> |-->
<!--    <router-link class="" to="/about">Торговые площадки</router-link> |-->
<!--    <router-link class="" to="/about">Отзывы</router-link>-->
    </ul>
  </div>
  <the-header msg="Учебный центр дополнительного профессионального образования"/>
  <router-view/>
  <the-footer/>
</template>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}


#nav a.router-link-exact-active {
  text-decoration: underline;
}
</style>
<script>
import TheHeader from "./components/TheHeader";
import TheFooter from "./components/TheFooter";
export default {
  components: {TheFooter, TheHeader}
}
</script>