<template>
  <div class="bg-blue-500  mt-10">
    <div class="footer w-11/12 lg:w-7/12 mx-auto text-white pt-4 pb-4">
      <div class="mx-auto relative">

        <div class=" grid grid-cols-1 lg:grid-cols-2 gap-3">
          <div class="flex flex-col lg:flex-row justify-start items-center  gap-6 relative">
            <div class="absolute h-56 w-56 top-24 -left-56"
                 style="background-image:url('/images/production/mail.gif'); opacity:0.9; background-size: cover;"></div>

            <div class="contact text-xl lg:text-3xl font-bold flex text-left gap-5 flex-col">

              <h1 class="text-2xl uppercase font-bold text-left">Свяжитесь с нами:</h1>
              <a class="footer" :href="'tel:' + getPhone ">{{ getPhone }}</a>
              <a class="footer" :href="'tel:' + getReservePhone">{{ getReservePhone }}</a>
              <a class="footer" :href="'mailto:' + getEmail">{{ getEmail }}</a>
              <div class="flex flex-row gap-2 justify-start hidden">
                <a href="#" class="w-12 h-12 relative opacity-90 hover:opacity-100 bg-white rounded-full text-3xl flex justify-center items-center">
                  <font-awesome-icon style="color:#0088cc" :icon="[ 'fab', 'telegram' ]"/>
                </a>
                <a href="#" class="w-12 h-12 relative opacity-90 hover:opacity-100 bg-white rounded-full text-3xl flex justify-center items-center">
                  <font-awesome-icon style="color:#4C75A3" :icon="[ 'fab', 'vk' ]" class=""/>
                </a>
                <a href="#" class="w-12 h-12 relative opacity-90 hover:opacity-100 bg-white rounded-full text-3xl flex justify-center items-center">
                  <font-awesome-icon style="color:#FF0000" :icon="[ 'fab', 'youtube' ]"/>
                </a>


              </div>
              <p class="text-2xl">121309, г. Москва, улица Филевская Б., дом 25, этаж 6, помещение 611</p>
            </div>
          </div>
          <div class="flex justify-end relative">
            <iframe style="border-radius: 360px 15px 150px 15px;"
                    src="https://yandex.ru/map-widget/v1/?um=constructor%3Aa238315709ac51c5f3d0aca245f6e9ae843d06847ab2bb3c714a77ffcfcd75ff&amp;source=constructor"
                    width="500" height="350" frameborder="0"></iframe>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheFooter",
  data() {
    return {}
  },
  computed: {
    getPhone() {
      console.log(this.$store.state.phone);
      return this.$store.state.phone
    },
    getReservePhone() {
      return this.$store.state.reservePhone
    },
    getEmail() {
      return this.$store.state.email
    },
  }
}
</script>

<style scoped>

</style>